import React, { Component } from 'react';

const bodyText1 = (
  <div>
    <p>Etsimme järjestyksenvalvojia Helsinkiin.</p>
    <p>Haemme joukkoomme reipashenkisiä ja motivoituneita järjestyksenvalvojia ravintola- ja tapahtumakohteisiin. Työvuorot painottuvat illoille ja öille, mutta tarjolla voi olla myös satunnaisia päivävuoroja. Hakijalta toivomme sitoutumista tarjottavaan työhön.</p>
    <p>Työskentely kohteet sijaitsevat Helsingissä tai pääkaupunkiseudulla. Voimassa oleva järjestyksenvalvojakortti on pakollinen.</p>
    <p>Katsomme eduksi hakijan vartijan tutkinto- tai korttikoulutuksen.</p>
    <p>Palkkauksessa sovelletaan majoitus-, ravintola- ja vapaa-ajan palveluita (Mara) koskevaa työehtosopimusta.</p>
    <p>NSA on kasvava ja kehittyvä yksityisen turvallisuusalan palveluita tarjoava yhtiö, jossa henkilöstöstä välitetään. Tarjoamme hyvät työkaverit ja hienon työyhteisön, jossa ammattitaitoa ja henkilökohtaista kehitystä arvostetaan.</p>
  </div>
);

const bodyText2 = (
  <div>
    <p>Etsimme järjestyksenvalvojia Tampereelle.</p>
    <p>Haemme joukkoomme reipashenkisiä ja motivoituneita järjestyksenvalvojia ravintola- ja tapahtumakohteisiin. Työvuorot painottuvat illoille ja öille. Hakijalta toivomme sitoutumista tarjottavaan työhön.</p>
    <p>Työskentely kohteet sijaitsevat Tampereella. Voimassa oleva järjestyksenvalvojakortti on pakollinen.</p>
    <p>Katsomme eduksi hakijan vartijan tutkinto- tai korttikoulutuksen sekä mahdollisuuden oman auton käyttöön ja työskentelyyn myös Tamperetta ympäröivillä alueilla.</p>
    <p>Palkkauksessa sovelletaan majoitus-, ravintola- ja vapaa-ajan palveluita (Mara) koskevaa työehtosopimusta.</p>
    <p>NSA on kasvava ja kehittyvä yksityisen turvallisuusalan palveluita tarjoava yhtiö, jossa henkilöstöstä välitetään. Tarjoamme hyvät työkaverit ja hienon työyhteisön, jossa ammattitaitoa ja henkilökohtaista kehitystä arvostetaan.</p>
  </div>
);

const bodyText3 = (
  <div>
    <p>Etsimme järjestyksenvalvojia Varsinais-Suomen alueelle.</p>
    <p>Haemme joukkoomme reipashenkisiä ja motivoituneita järjestyksenvalvojia ravintola- ja tapahtumakohteisiin. Työvuorot painottuvat pääasiassa viikonloppu illoille ja öille. Hakijalta toivomme sitoutumista tarjottavaan työhön.</p>
    <p>Työskentely kohteet sijaitsevat Varsinais-Suomen alueella, joten oma auto on välttämätön. Voimassa oleva järjestyksenvalvojakortti on pakollinen.</p>
    <p>Katsomme eduksi hakijan vartijan tutkinto- tai korttikoulutuksen.</p>
    <p>Palkkauksessa sovelletaan majoitus-, ravintola- ja vapaa-ajan palveluita (Mara) koskevaa työehtosopimusta.</p>
    <p>NSA on kasvava ja kehittyvä yksityisen turvallisuusalan palveluita tarjoava yhtiö, jossa henkilöstöstä välitetään. Tarjoamme hyvät työkaverit ja hienon työyhteisön, jossa ammattitaitoa ja henkilökohtaista kehitystä arvostetaan.</p>
  </div>
);

const bodyText4 = (
  <div>
    <p>Etsimme järjestyksenvalvojia Ouluun.</p>
    <p>Haemme joukkoomme reipashenkisiä ja motivoituneita järjestyksenvalvojia ravintolakohteisiin. Työvuoroja on tarjota 1–2 vuoroa viikossa ja ne painottuvat viikonloppu illoille ja öille. Työ soveltuu erinomaisesti opiskelijalle tai henkilölle, joka etsii lisätyötä toisen työn oheen.</p>
    <p>Työskentely kohteet sijaitsevat Oulussa. Voimassa oleva järjestyksenvalvojakortti on pakollinen.</p>
    <p>Katsomme eduksi hakijan vartijan tutkinto- tai korttikoulutuksen.</p>
    <p>Palkkauksessa sovelletaan majoitus-, ravintola- ja vapaa-ajan palveluita (Mara) koskevaa työehtosopimusta.</p>
    <p>NSA on kasvava ja kehittyvä yksityisen turvallisuusalan palveluita tarjoava yhtiö, jossa henkilöstöstä välitetään. Tarjoamme hyvät työkaverit ja hienon työyhteisön, jossa ammattitaitoa ja henkilökohtaista kehitystä arvostetaan.</p>
  </div>
);

const bodyText5 = (
  <div>
    <p>Haemme joukkoomme reipashenkisiä ja asiakaspalvelukeskeisiä järjestyksenvalvojia ja eteispalvelutyöntekijöitä Helsingissä sijaitsevalle Finlandia-talolle. Finlandia-talolla järjestetään erilaisia ja erikokoisia tapahtumia läpi vuoden. Työvuoroja on tarjolla läpi viikon maanantaista sunnuntaihin, ja työajat sijoittuvat aamusta myöhäiseen iltaan. Työt alkavat 1.1.2025 tai sopimuksen mukaan.</p>
    <p>Hakijalta toivomme sitoutumista tarjottavaan työhön. Järjestyksenvalvojan tehtäviin hakeutuvilta edellytämme voimassa olevaa järjestyksenvalvojakorttia.</p>
    <p>Palkkauksessa sovelletaan matkailu-, ravintola- ja vapaa-ajan palveluita koskevaa työehtosopimusta.</p>
    <p>NSA on kasvava ja kehittyvä yksityisen turvallisuusalan palveluita tarjoava yhtiö, jossa henkilöstöstä välitetään. Tarjoamme hyvät työkaverit ja hienon työyhteisön, jossa ammattitaitoa ja henkilökohtaista kehitystä arvostetaan.</p>
  </div>
);

const JobList = [
  {
    id: 'd1fbc598-0686-4431-97a2-4b9bf4d6d2f1',
    title: "Järjestyksenvalvojia ja eteispalvelutyöntekijöitä Finlandia-talolle",
    leadIn: "Haemme joukkoomme reipashenkisiä ja asiakaspalvelukeskeisiä järjestyksenvalvojia ja eteispalvelutyöntekijöitä Helsingissä sijaitsevalle Finlandia-talolle.",
    quantity: 'Jatkuva haku',
    city: 'Helsinki',
    body: bodyText5,
    emails: ["ossi.pietilainen@nordicsec.fi", "mikko.seppanen@nordicsec.fi"],
    type: 0
  },
  {
    id: "6ad35e3d-65c6-4935-8494-568678925208",
    title: "Järjestyksenvalvojia Helsinkiin",
    leadIn: "Etsimme järjestyksenvalvojia Helsinkiin",
    quantity: "Jatkuva haku",
    city: "Helsinki",
    body: bodyText1,
    emails: ["ossi.pietilainen@nordicsec.fi", "mikko.seppanen@nordicsec.fi"],
    type: 0,
  },
  {
    id: "12ad35e3d-65c6-4935-8494-568678925209",
    title: "Järjestyksenvalvojia Tampereelle",
    leadIn: "Etsimme järjestyksenvalvojia Tampereelle",
    quantity: "Jatkuva haku",
    city: "Tampere",
    body: bodyText2,
    emails: ["jan.johansson@nordicsec.fi", "tero.toivanen@nordicsec.fi"],
    type: 0,
  },
  {
    id: "23ad35e3d-65c6-4935-8494-568678925210",
    title: "Järjestyksenvalvojia Varsinais-Suomen alueelle",
    leadIn: "Etsimme järjestyksenvalvojia Varsinais-Suomen alueelle",
    quantity: "Jatkuva haku",
    city: "Varsinais-Suomi",
    body: bodyText3,
    emails: ["jyrki.varvas@nordicsec.fi"],
    type: 0,
  },
  {
    id: "34ad35e3d-65c6-4935-8494-568678925211",
    title: "Järjestyksenvalvojia Ouluun",
    leadIn: "Etsimme järjestyksenvalvojia Ouluun",
    quantity: "Jatkuva haku",
    city: "Oulu",
    body: bodyText4,
    emails: ["miika.moronen@nordicsec.fi", "jyrki.varvas@nordicsec.fi"],
    type: 0,
  },
];

export default JobList;